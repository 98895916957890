import {withTranslation} from '@wix/yoshi-flow-editor'
import {getEventTicketLimitPerOrder, isRegistrationClosedVisible} from '@wix/wix-events-commons-statics'
import {DetailsPageAppProps} from '../../../../../app/interfaces'
import {connect} from '../../../../../runtime-context/context'
import {PricingOptionsQuantityOwnProps, PricingOptionsQuantityRuntimeProps} from './interfaces'
import {PricingOptionQuantity as Presentation} from './pricing-option-quantity'

export const mapRuntime = ({state}: DetailsPageAppProps): PricingOptionsQuantityRuntimeProps => {
  const limit = getEventTicketLimitPerOrder(state.event)
  const registrationClosed = isRegistrationClosedVisible(state.event)

  return {
    selectedQuantity: 0, // TODO
    max: Math.min(5, limit), // TODO,
    disabled: !limit || registrationClosed,
  }
}

export const PricingOptionQuantity = withTranslation()(
  connect<PricingOptionsQuantityOwnProps, PricingOptionsQuantityRuntimeProps>(mapRuntime)(Presentation),
)
export * from './interfaces'
