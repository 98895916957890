import {withTranslation} from '@wix/yoshi-flow-editor'
import {getFormattedMoney} from '@wix/wix-events-commons-statics'
import {DetailsPageAppProps} from '../../../../../app/interfaces'
import {connect} from '../../../../../runtime-context/context'
import {PricingOptionsTotalOwnProps, PricingOptionsTotalRuntimeProps} from './interfaces'
import {PricingOptionTotal as Presentation} from './pricing-option-total'

export const mapRuntime = ({}: DetailsPageAppProps): PricingOptionsTotalRuntimeProps => {
  return {
    total: getFormattedMoney({amount: '0', currency: 'USD'}),
  }
}

export const PricingOptionTotal = withTranslation()(
  connect<PricingOptionsTotalOwnProps, PricingOptionsTotalRuntimeProps>(mapRuntime)(Presentation),
)
export * from './interfaces'
