import {getEventTicketLimitPerOrder, shouldTicketLimitTooltipShow} from '@wix/wix-events-commons-statics'
import {DetailsPageAppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/context'
import {getSelectedTicketQuantity, getSelectedTicketsQuantity} from '../../../../../selectors/selected-tickets'
import {LimitReachedTooltipRuntimeProps, LimitReachedTooltipOwnProps} from './interfaces'
import {LimitReachedTooltip as Presentation} from './limit-reached-tooltip'

const mapRuntime = (
  {state}: DetailsPageAppProps,
  {ticket}: LimitReachedTooltipOwnProps,
): LimitReachedTooltipRuntimeProps => {
  const maxTicketsPerReservation = getEventTicketLimitPerOrder(state.event)
  const selectedQuantity = getSelectedTicketQuantity(state, ticket.id)
  const availableTicketsQuantity = maxTicketsPerReservation - getSelectedTicketsQuantity(state)

  return {
    show: shouldTicketLimitTooltipShow(availableTicketsQuantity, selectedQuantity),
    maxTicketsPerReservation,
  }
}

export const LimitReachedTooltip = connect<LimitReachedTooltipOwnProps, LimitReachedTooltipRuntimeProps>(mapRuntime)(
  Presentation,
)
